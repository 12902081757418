/**
 * Arquivo de Rotas
 */

import React, { Suspense, lazy } from "react";
import { history } from './redux/store';
import { Switch, Route } from "react-router";
import { ConnectedRouter } from 'connected-react-router';

const InitialPage = lazy(() => import("./pages/initial"));
const AboutPage = lazy(() => import("./pages/about"));
const SolutionsPage = lazy(() => import("./pages/solutions"));
const ContactPage = lazy(() => import("./pages/contact"));

export default () => <ConnectedRouter history={history}>
    <Suspense fallback={<></>}>
        <Switch>
            {/* Página Inicial */}
            <Route exact path="/">
                <InitialPage />
            </Route>
            {/* Página Quem somos */}
            <Route path="/about">
                <AboutPage />
            </Route>
            {/* Página Soluções */}
            <Route path="/solutions">
                <SolutionsPage />
            </Route>
            {/* Página Soluções */}
            <Route path="/contact">
                <ContactPage />
            </Route>
        </Switch>
    </Suspense>
</ConnectedRouter>