import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { ModalReducer } from "./modal";
import { ChatReducers, ChatSagas } from "./chat";
import { formPostState } from "./form/post";
import { context } from "./context";
import { postFormSaga } from "./form/post";

export const reducers = (history) => combineReducers({
    router: connectRouter(history),
    ...ChatReducers,
    context,
    ModalReducer,
    formContato: formPostState
})

export const Sagas = [...ChatSagas, postFormSaga];