import {
    createActions,
    handleActions
} from "redux-actions";
import createChatSaga from './saga';

export const CREATE_CHAT = "CREATE_CHAT";
export const CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS";
export const CREATE_CHAT_FAILURE = "CREATE_CHAT_FAILURE";

export const {
    createChat,
    createChatSuccess,
    createChatFailure,
} = createActions({
    [CREATE_CHAT]: numero => numero,
    [CREATE_CHAT_SUCCESS]: resultado => resultado,
    [CREATE_CHAT_FAILURE]: erro => erro,
})

const INITIAL_STATE = {
    loading: false,
    loaded: false,
    error: null,
    token: null,
}

const chat = handleActions({
    [CREATE_CHAT]: state => ({
        ...state,
        loading: true,
        loaded: false,
        error: false,
    }),
    [CREATE_CHAT_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        error: false,
        token: payload
    }),
    [CREATE_CHAT_FAILURE]: (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: true,
        error: payload,
    })
}, INITIAL_STATE)

export { chat, createChatSaga };