const INITIAL_STATE = {
    modais: {}
}

export const openModal = id => ({
    type: 'OPEN_MODAL',
    id,
})
export const closeModal = id => ({
    type: 'CLOSE_MODAL',
    id,
})
export const toggleModal = id => ({
    type: 'TOGGLE_MODAL',
    id,
})


export const ModalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'OPEN_MODAL': {
            const id = action.id
            let modais = state.modais
            const modal = action
            modal.show = true
            modais[id] = modal
            return {
                modais
            }
        }
        case 'CLOSE_MODAL': {
            const id = action.id
            let modais = state.modais
            let modal = modais[id]
            if (!modal) modal = {}
            modal.show = false
            modais[id] = modal
            return {
                modais
            }
        }
        case 'TOGGLE_MODAL': {
            const id = action.id
            let modais = state.modais
            let modal = modais[id]
            if (!modal) modal = {}
            modal.show = !modal.show
            modais[id] = modal
            return {
                modais
            }
        }
        default:
            return state
    }
}