/**
 * Componentes Header e footer, globais para uso comum em todas as páginas do portal
 */

import React from 'react';
import { Provider } from "react-redux";
import './assets/reset.css';
import './App.css';
import { store } from './redux/store';
import Routes from './routes';

import HeaderComponent from './components/globals/header';
import FooterComponent from './components/globals/footer';

function App() {
  return (
    <Provider store={store}>
      <div className="container">
        <HeaderComponent />
        <div className="container-body">
          <Routes/>
        </div>
        <FooterComponent />
      </div>
    </Provider>
  );
}

export default App;