import {
    put,
    call,
    takeLatest
} from "redux-saga/effects";
import {
    post
} from "request-promise";
import {
    POST_FORM,
    postFormFailure,
    postFormSuccess
} from ".";

const postChatCreate = async payload => {
    return post(`${process.env.REACT_APP_URL_MOTOR_ENVIO}`, {
        body: {
            platform: 'DSB_PORTAL',
            channel: 'email',
            receiver: 'template',
            vars: payload,
            idTemplate: 'portal_dsb_form_contato',
        },
        json: true
    }).
    then(result => {
      return {
          error: false,
          msg: result.success ? 'sucesso' : 'erro',
      }  
    })
    .catch(error => {
        return {
            error: true,
            msg: 'erro' + error.message
        }
    })
}

function* create({
    payload
}) {
    
    const result = yield call(postChatCreate, payload);
    if (result.error === true) 
        return yield put(postFormFailure(result));
    
    return yield put(postFormSuccess(result));
}

function* postFormSaga() {
    yield takeLatest(POST_FORM, create)
}

export default postFormSaga;