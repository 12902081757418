import { put, call, takeLatest } from "redux-saga/effects";
import { post } from "request-promise";
import { createChatSuccess, createChatFailure, CREATE_CHAT } from ".";

function* postChatCreate() {
    return yield post(`${process.env.REACT_APP_BOT_URL}/api/chat/create`, {
        form: {
            channel: process.env.REACT_APP_BOT_CHANNEL
        }
    });
}

function* create() {
    try {
        const result = yield call(postChatCreate);
        yield put(createChatSuccess(result.replace(/"/g, '')));
    } catch (error) {
        yield put(createChatFailure(error.message));
        
    }
}
function* createChatSaga() {
    yield takeLatest(CREATE_CHAT,create)
}
export default createChatSaga;