import { put, call, takeLatest } from "redux-saga/effects";
import { post } from "request-promise";
import { postMessageSuccess, postMessageFailure, POST_MESSAGE } from ".";

function* postChatCreate({ message, id, token,  button }) {
    return yield post(`${process.env.REACT_APP_BOT_URL}/api/chat`, {
        body: {
            message,
            id,
            access_token: token,
            postback: button && {
                title: button.title,
                payload: button.payload,
            }
        },
        json: true
    });
}

function* create({ payload }) {
    try {
        const result = yield call(postChatCreate, payload);
        yield put(postMessageSuccess(result.replace(/"/g, '')));
    } catch (error) {
        yield put(postMessageFailure(error.message));

    }
}
function* postMessageSaga() {
    yield takeLatest(POST_MESSAGE, create)
}

export default postMessageSaga;