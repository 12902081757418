import {
    createActions,
    handleActions
} from "redux-actions";
import getMessageSaga from './saga';

export const GET_MESSAGE = "GET_MESSAGE";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAILURE = "GET_MESSAGE_FAILURE";
export const DISABLE_ALL_BUTTONS = "DISABLE_ALL_BUTTONS";
export const READ_MESSAGES = "READ_MESSAGES";

const updateButtonState = (messages, { type, choosedMessage }) => {
    messages = messages.map(message => {
        if (!message.buttons || message.buttons.length === 0) return message;
        if (type === 'DISABLE_ALL_BUTTONS') { // Desabilita todos os botões
            message = message.buttons.map(button => {
                button.disabled = true;
                return button;
            });
        }
        return message;
    })
    return messages;
}

export const {
    getMessage,
    getMessageSuccess,
    getMessageFailure,
    disableAllButtons,
    readMessages,
} = createActions({
    [GET_MESSAGE]: ({
        token,
        id,
    }) => ({
        token,
        id,
    }),
    [GET_MESSAGE_SUCCESS]: resultado => resultado,
    [GET_MESSAGE_FAILURE]: erro => erro,
    [DISABLE_ALL_BUTTONS]: () => ({}),
    [READ_MESSAGES]: () => ({}),
})

const INITIAL_STATE = {
    loading: false,
    loaded: false,
    error: null,
    messages: [],
    newMessagesCount: 0,
}

const messages = handleActions({
    [GET_MESSAGE]: state => ({
        ...state,
        loading: true,
        loaded: false,
        error: false,
    }),
    [GET_MESSAGE_SUCCESS]: (state, {
        payload
    }) => {
        let newMessagesCount = state.newMessagesCount + payload.length;
        const messages = state.messages;
        if (payload.length > 0) messages.push(...payload);
        return {
            ...state,
            loading: false,
            loaded: true,
            error: false,
            messages,
            newMessagesCount
        }
    },
    [GET_MESSAGE_FAILURE]: (state, {
        payload
    }) => ({
        ...state,
        loading: false,
        loaded: true,
        error: payload,
    }),
    [DISABLE_ALL_BUTTONS]: state => {
        let messages = state.messages;
        messages = messages.map(message => {
            message.buttons = message.buttons.map(button => {
                button.disabled = true;
                return button;
            });
            return message;
        })
        return {
            ...state,
            messages,
        }
    },
    [READ_MESSAGES]: state => ({
        ...state,
        newMessagesCount: 0,
    })
}, INITIAL_STATE)

export {
    messages,
    getMessageSaga
};