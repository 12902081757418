import {
    createActions,
    handleActions
} from "redux-actions";
import postMessageSaga from './saga';

export const POST_MESSAGE = "POST_MESSAGE";
export const POST_MESSAGE_SUCCESS = "POST_MESSAGE_SUCCESS";
export const POST_MESSAGE_FAILURE = "POST_MESSAGE_FAILURE";

export const {
    postMessage,
    postMessageSuccess,
    postMessageFailure,
} = createActions({
    [POST_MESSAGE]: ({
        message,
        token,
        id,
        button,
    }) => ({
        message,
        token,
        id,
        button, 
    }),
    [POST_MESSAGE_SUCCESS]: resultado => resultado,
    [POST_MESSAGE_FAILURE]: erro => erro,
})

const INITIAL_STATE = {
    loading: false,
    loaded: false,
    error: null,
    id: null,
}

const postedMessage = handleActions({
    [POST_MESSAGE]: state => ({
        ...state,
        loading: true,
        loaded: false,
        error: false,
    }),
    [POST_MESSAGE_SUCCESS]: (state, {
        payload
    }) => ({
        ...state,
        loading: false,
        loaded: true,
        error: false,
        id: state.id ? state.id : payload
    }),
    [POST_MESSAGE_FAILURE]: (state, {
        payload
    }) => ({
        ...state,
        loading: false,
        loaded: true,
        error: payload,
    })
}, INITIAL_STATE)

export {
    postedMessage,
    postMessageSaga
};