/**
 * Header | Comum para todas as páginas
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import './index.scss';
import logo from './header-img/logo-dsb.png'
import { push } from 'connected-react-router';
import Toggle from '../toggle';


const HeaderComponent = () => {
    const dispatch = useDispatch();

    //Navegação para menu inicial a partir da imagem de logo DSB
    const navigation = [
        {
            link: '/'
        },
    ]

    //Navegação para menu a partir da lista do header
    const menu = [
        {
            text: "Home",
            link: '/'
        },
        {
            text: "Quem somos",
            link: '/about'
        },
        {
            text: "Soluções",
            link: '/solutions'
        },
        // {
        //     text: "Blog",
        //     link: '#'
        // },
        {
            text: "Contato",
            link: '/contact'
        },
    ]

    const navigate = (link) => () => dispatch(push(`/${link}`));

    return (
        <header className="header">
            <div className="children">
                {
                    navigation.map(nav => <a href={nav.link} onClick={navigate(nav.link)}><img src={logo} alt="logo dsb" /></a>)
                }
                {/*classe (menu) para menu padrão é exibida até largura mínima de 824px*/}
                <nav className="menu">
                    {
                        menu.map(item => <a key={item.text} href={item.link} onClick={navigate(item.link)}>{item.text}</a>)
                    }
                </nav>
                {/*classe (toggle) para menu responsivo é exibida até largura máxima de 823px*/}
                <nav className="toggle">
                    <Toggle />
                </nav>
            </div>
        </header>
    )
};

export default HeaderComponent;
