import {
    createActions,
    handleActions
} from "redux-actions";
import postFormSaga from './saga';

export const POST_FORM = "POST_FORM";
export const POST_FORM_SUCCESS = "POST_FORM_SUCCESS";
export const POST_FORM_FAILURE = "POST_FORM_FAILURE";

export const {
    postForm,
    postFormSuccess,
    postFormFailure,
} = createActions({
    [POST_FORM]: data => data,
    [POST_FORM_SUCCESS]: resultado => resultado,
    [POST_FORM_FAILURE]: erro => erro,
})

const INITIAL_STATE = {
    loading: false,
    loaded: false,
    error: false,
}
export { postFormSaga };
export const formPostState = handleActions({
    [POST_FORM]: state => ({
        ...state,
        loading: true,
        loaded: false,
        error: false,
    }),
    [POST_FORM_SUCCESS]: (state, {payload}) => ({
        ...state,
        ...payload,
        loading: false,
        loaded: true,
        error: false
    }),
    [POST_FORM_FAILURE]: (state, {payload}) => ({
        ...state,
        ...payload,
        loading: false,
        loaded: true,
        error: true,
    })
}, INITIAL_STATE)
