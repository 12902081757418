import { put, call, takeLatest } from "redux-saga/effects";
import { post } from "request-promise";
import { getMessageSuccess, getMessageFailure, GET_MESSAGE } from ".";

function* getChatCreate({ message, id, token }) {
    return yield post(`${process.env.REACT_APP_BOT_URL}/api/chat/messages`, {
        form: {
            id,
            access_token: token
        },
    });
}

function* create({ payload }) {
    try {
        const result = yield call(getChatCreate, payload);
        yield put(getMessageSuccess(JSON.parse(result)));
    } catch (error) {
        yield put(getMessageFailure(error.message));
        
    }
}
function* getMessageSaga() {
    yield takeLatest(GET_MESSAGE, create)
}

export default getMessageSaga;