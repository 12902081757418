/**
 * Footer | Comum para todas as páginas via redux e routes
 */
import React from 'react';
import { useDispatch } from "react-redux";
import './index.scss';
import logo from './img-footer/logo-dsb.png'
import { push } from 'connected-react-router';


const FooterComponent = () => {
    const dispatch = useDispatch();

    //Navegação para menu inicial a partir da imagem de logo DSB
    const navigation = [
        {
            link: '/'
        },
    ]

    /*NAVEGAÇÕES DA SEGUNDA COLUNA(colTwo) E MIDIAS SOCIAIS(sociais) ESTÃO PRONTAS PORÉM OCULTAS POIS NO MOMENTO NÃO ESTARÃO DISPONIVEIS PARA OS USUÁRIOS*/

    //Navegação a partir do footer
    const colOne = [
        {
            text: "DSB",
            link: '/'
        },
        {
            text: "Quem somos",
            link: '/about'
        },
        {
            text: "Soluções",
            link: '/solutions'
        },
    ]

    // const colTwo = [
    //     {
    //         text: "Novidades",
    //         link: '#'
    //     },
    //     {
    //         text: "Blog",
    //         link: '#'
    //     },
    //     {
    //         text: "Noticias",
    //         link: '#'
    //     },
    // ]

    const colThree = [
        {
            text: "Contato",
            link: '/contact'
        },
        {
            text: "Entre em contato",
            link: '/contact'
        },
        {
            text: "Trabalhe conosco",
            link: '/contact'
        },
    ]

    // const social = [
    //     {
    //         img: "facebook.png",
    //         alt: 'logo facebook',
    //         link: 'http://facebook.com'
    //     },
    //     {
    //         img: "linkedin.png",
    //         alt: 'logo linkedin',
    //         link: 'http://linkedin.com'
    //     },
    //     {
    //         img: "twitter.png",
    //         alt: 'logo twitter',
    //         link: 'http://twitter.com'
    //     },
    //     {
    //         img: "youtube.png",
    //         alt: 'logo youtube',
    //         link: 'http://youtube.com'
    //     },
    // ]

    const navigate = (link) => () => dispatch(push(`/${link}`));

    return (
        <footer className="footer">
            <nav className="footer-children">
                <div className="logo">
                    {
                        navigation.map(nav => <a href={nav.link} onClick={navigate(nav.link)}><img src={logo} alt="logo dsb" /></a>)
                    }
                </div>
                <div className="menu">
                    <div className="col">
                        {
                            colOne.map(item => <a key={item.text} href={item.link} onClick={navigate(item.link)}>{item.text}</a>)
                        }
                    </div>
                    {/* <div className="col">
                        {
                            colTwo.map(item => <a key={item.text} href={item.link} onClick={navigate(item.link)}>{item.text}</a>)
                        }
                    </div> */}
                    <div className="col">
                        {
                            colThree.map(item => <a key={item.text} href={item.link} onClick={navigate(item.link)}>{item.text}</a>)
                        }
                    </div>
                </div>
                {/* <div className="social">
                    {
                        social.map(media => (
                            <div key={media.img}>
                                <a href={media.link} target="_blank" onClick={navigate(media.link)} ><img src={require(`./img-footer/${media.img}`)} alt={media.alt} /></a>
                            </div>)
                        )
                    }
                </div> */}
            </nav>
            <div className="divH"><h1>© 2019 DSB | Todos os direitos reservados.</h1></div>
        </footer>
    )
};

export default FooterComponent;
